<template>
  <div class="app-container equityheader summary-page">
    <eHeader
      ref="header"
      :query="query"
      @add="addHandle"
    />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column label="信号源">
        <template slot-scope="scope">
          <div>{{scope.row.signalUserName}}</div>
          <div>{{scope.row.signalLogin}}</div>
        </template>
      </el-table-column>
      <el-table-column label="订阅时长">
        <template slot-scope="scope">
          <div>{{getTime(scope.row)}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="couponCode" label="兑换码"/>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <span>{{getCreateTime(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="expireTime" label="截止时间">
        <template slot-scope="scope">
          <span>{{scope.row.expireTime.split(' ')[0]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channelProtocolNo" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="使用者">
        <template slot-scope="scope">
          <span v-if="scope.row.copyUserName">{{scope.row.copyUserName}}  #{{scope.row.copyLoginOrder}}</span>
          <div v-if="scope.row.copyUserName">{{scope.row.copyLogin}}</div>
          <div v-if="!scope.row.copyUserName">-</div>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <add-coupon
      ref="addcoupon"
      @update="updateHandle"
    ></add-coupon>
  </div>
</template>

<script>
  import addCoupon from '@/components/financial/couponManagement/addCoupon'
  import { parseTime } from '../../../utils'
  import initData from '@/mixins/initData'
  import eHeader from '@/components/financial/couponManagement/header'
  export default {
    components: { eHeader,addCoupon },
    mixins: [initData],
    data() {
      return {
        dialogShow: false,
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      updateHandle(){
        this.page = 0;
        this.query = {};
        this.init();
      },
      addHandle(){
        this.$refs.addcoupon.dialog = true;
      },
      beforeInit() {
        this.url = '/community/crm/srCommunityCouponInfo'
        this.params = {
          page: this.page,
          size: this.size,
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(this.query.status){
          this.params.status = this.query.status;
        }
        if(this.query.signalInfo){
          this.params.signalInfo = this.query.signalInfo;
        }
        return true;
      },
      getTime(row){
        switch (row.periodType) {
          case 1:
            return '1个月';
          case 2:
            return '2个月';
          case 3:
            return '3个月';
        }
      },
      getStatus(row){
        switch (row.status) {
          case 1:
            return '有效';
          case 2:
            return '已使用';
          case 3:
            return '已过期';
        }
      },
      getCreateTime(row){
        return row.createTime;
      }
    }
  }
</script>

<style lang="less" scoped>
.equityheader {

}
</style>
