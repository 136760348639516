import request from '@/utils/request'

// audit
export function addCoupon(data) {
  return request({
    url: '/community/crm/srCommunityCouponInfo',
    method: 'post',
    data
  })
}
