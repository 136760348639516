<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    title="新增"
    width="700px"
  >
    <el-form ref="form" :model="form"  size="small" :rules="rules" label-width="120px">
      <el-form-item label="所属社区">
        <el-select
          v-model="form.siteId"
          filterable
          placeholder="请选择"
          style="width: 500px;"
        >
          <el-option
            v-for="item in siteList"
            :key="item.id"
            :label="item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item  label="订阅时长" prop="time">
        <el-select
          placeholder="请选择"
          v-model="form.time"
          style="width: 500px;"
        >
          <el-option
            v-for="(item,index) in timeList"
            :key="index"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item  label="截止日期" prop="endTime">
        <el-date-picker
          v-model="form.endTime"
          type="date"
          class="filter-item"
          style="width: 500px;"
          placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="信号源">
        <div>
          <el-input v-model="query" clearable placeholder="输入客户ID、姓名或MT4" style="width: 400px;"  @keyup.enter.native="toQuery"/>
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
        </div>
        <el-table
          ref="singleTable"
          :data="customerList"
          highlight-current-row
          @current-change="handleCurrentChange"
          style="width: 500px;max-height:400px;">
          <el-table-column property="loginOrder" label="MT4序号"> </el-table-column>
<!--          <el-table-column property="login" label="MT4"> </el-table-column>-->
          <el-table-column property="userId" label="客户ID"> </el-table-column>
          <el-table-column property="userName" label="客户名称"> </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item
        label="数量"
        prop="amount"
      >
        <el-input
          v-model="form.amount"
          placeholder="请输入数量"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button
        type="text"
        @click="cancel"
      >取消</el-button>
      <el-button
        :loading="loading"
        type="primary"
        @click="doSubmit"
      >确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import  { noZeroInit } from '@/utils/validate'
import { addCoupon } from '@/api/financial/coupon'
import { selectRecommendedTodays} from '@/api/community/srRecommendedToday'
import { getByteLen } from '@/utils/validate'
import { initData } from '@/api/data'
export default {
  props: {
    sup_this: {
      type: Object,
      default: null
    }
  },
  created() {
    this.initSiteList();
  },
  computed:{
  },
  data() {
    let validateAmount = (rule, value, callback) => {
      if(value === ''){
        callback(new Error('请输入数量'));
      }else {
        if(!noZeroInit(value)){
          callback(new Error('请输入正整数'));
        }else {
          callback();
        }
      }
    }
    return {
      loading: false,
      dialog: false,
      customerList:[],
      selectCustomer:null,
      siteList:[],
      timeList:[
        {label: '1个月', value: 1},
        {label: '2个月', value: 2},
        {label: '3个月', value: 3},
      ],
      query: '',
      form: {
        siteId: '',
        time: '',
        endTime: '',
        amount: '',
      },
      rules:{
        siteId:[
          { required: true, message: '请选择所属社区', trigger: 'change' }
        ],
        time:[
          { required: true, message: '请选择订阅时长', trigger: 'change' }
        ],
        endTime:[
          { required: true, message: '请选择截止日期', trigger: 'change' }
        ],
        amount:[
          { validator: validateAmount, trigger: 'blur' },
        ]
      },
    }
  },
  methods: {
    getByteLen,
    cancel() {
      this.resetForm()
    },
    initSiteList(){
      const url = 'cms/crm/srSite'
      const sort = 'sortNo,asc'
      const params = {sort: sort,page:0,size:1000}
      initData(url, params).then(res => {
          this.siteList = res.content
          this.form.siteId = this.siteList[0].id
        }).catch(err => {
          reject(err)
        })
    },
    handleSuccess(response){
        //上传成功要处理的事
        this.form.backimg=response.data.content[0].url;
    },
    handleCurrentChange(val) {
      this.selectCustomer = val;
      console.log(this.selectCustomer,'selectCustomer')
    },
    toQuery() {
      if(this.query==null) {
         this.$message.error('请输入查询条件');
         return
      }
      const params = { userInfo: this.query };
      selectRecommendedTodays(params).then(res => {
        this.customerList = res;
      })
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if(!this.selectCustomer){
            this.$message('请添加信号');
            return;
          }
          console.log(this.$parseTime(this.form.endTime));
          let hanlderTime = this.$parseTime(this.form.endTime).split(' ')[0];
          let params = {
            siteId: this.form.siteId,
            signalMtid: this.selectCustomer.mt4accountId,
            periodType: this.form.time,
            expireTime: hanlderTime + ' 23:59:59',
            amount: this.form.amount,
          }
          this.loading = true;
          addCoupon(params).then((res) => {
            console.log(res);
            this.loading = false;
            this.resetForm();
            this.$message({
              message: '添加成功',
              type: 'success'
            });
            this.$emit('update');
          }).catch((error) => {
            this.loading = false;
          });
        }
      })
    },
    resetForm() {
      this.dialog = false;
      this.$refs['form'].resetFields();
      this.selectCustomer = null;
      this.customerList = []
      this.form = {
        siteId: '',
        time: '',
        endTime: '',
      };
    }
  }
}
</script>

<style scoped>

</style>
